import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ContactFormBusiness from "../components/contactform-business"

const Contact = ({ data, location }) => {


  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="オンライン商談" />
      <div className="post-header">
        <h1>オンライン商談</h1>
      </div>
      <div className="post-body">
        <StaticImage
          layout="constrained"
          src="../images/online.jpg"
          max-width={720}
          alt="申し込み方法"
        />
        <iframe width="100%" height={600} style={{ border: 0 }} src="https://calendar.google.com/calendar/embed?src=6m0c2131fodel9er422l5flke0%40group.calendar.google.com&amp;ctz=Asia%2FTokyo" frameborder={0} scrolling="no" title="Calendar"></iframe>
        <p>下記フォーマットにご記入いただき、内容をご確認のうえ、送信してください。</p>
        <ContactFormBusiness />

      </div>
    </Layout>
  )
}

export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
