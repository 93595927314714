import React from "react"
// import firebase from "gatsby-plugin-firebase-v9.0"
import firebase from "gatsby-plugin-firebase"
// import firebase from "firebase/compat/app"

export default class ContactFormBusiness extends React.Component {
  constructor() {
    super()
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      isSubmitted: false,
    }
  }

  onSubmit(e) {
    e.preventDefault()
    let data = {}
    data.name = e.target.name.value
    data.email = e.target.email.value
    data.company = e.target.company.value
    data.tel = e.target.tel.value
    data.date01 = e.target.date01.value
    data.hour01 = e.target.hour01.value
    data.minute01 = e.target.minute01.value
    data.date02 = e.target.date02.value
    data.hour02 = e.target.hour02.value
    data.minute02 = e.target.minute02.value
    data.message = e.target.message.value
    data.ua = window.navigator.userAgent
    let sendBusiness = firebase.functions().httpsCallable('sendBusiness');
    sendBusiness(data)
    e.target.name.value = ""
    e.target.email.value = ""
    e.target.company.value = ""
    e.target.tel.value = ""
    e.target.date01.value = ""
    e.target.hour01.value = ""
    e.target.minute01.value = ""
    e.target.date02.value = ""
    e.target.hour02.value = ""
    e.target.minute02.value = ""
    e.target.message.value = ""
    e.target.value = ""
    this.setState({ isSubmitted: true })
  }

  render() {
    let contactForm
    if (this.state.isSubmitted) {
      contactForm = (
        <div className='contact-submit-message'>
          <h3>送信完了</h3>
          <p>メッセージは正常に送信されました。</p>
          <p>数営業日中に担当者より返信が届きます。</p>
        </div>
      )
    } else {
      contactForm = (
        <div className='contact-submit-message'>
          <p>
            <input type="submit" value="送信" className="submit_button" />
          </p>
        </div>
      )
    }
    return (
      <div className='contact-form'>
        <form onSubmit={this.onSubmit}>
          <p>
            <input
              type="text"
              name="name"
              placeholder="氏名（必須）"
              required
            />
          </p>
          <p>
            <input
              type="email"
              name="email"
              placeholder="メールアドレス（必須）"
              required
            />
          </p>
          <p>
            <input
              type="text"
              name="company"
              placeholder="会社名（必須）"
              required
            />
          </p>
          <p>
            <input
              type="tel"
              name="tel"
              placeholder="電話番号"
            />
          </p>
          <p>
            <label htmlFor="date01">
              第一希望 (必須)<br />
            </label>
            <input
              type="date"
              name="date01"
              width="250px"
              required
            />
            <select name="hour01" >
              <option value="09時">09時</option>
              <option value="10時">10時</option>
              <option value="11時">11時</option>
              <option value="12時">12時</option>
              <option value="13時">13時</option>
              <option value="14時">14時</option>
              <option value="15時">15時</option>
              <option value="16時">16時</option>
              <option value="17時">17時</option>
            </select>
            <select name="minute01" >
              <option value="0分">0分</option>
              <option value="15分">15分</option>
              <option value="30分">30分</option>
              <option value="45分">45分</option>
            </select>
          </p>
          <p>
            <label htmlFor="date02">
              第二希望<br />
            </label>
            <input
              type="date"
              name="date02"
            />
            <select name="hour02" >
              <option value="09時">09時</option>
              <option value="10時">10時</option>
              <option value="11時">11時</option>
              <option value="12時">12時</option>
              <option value="13時">13時</option>
              <option value="14時">14時</option>
              <option value="15時">15時</option>
              <option value="16時">16時</option>
              <option value="17時">17時</option>
            </select>
            <select name="minute02" >
              <option value="0分">0分</option>
              <option value="15分">15分</option>
              <option value="30分">30分</option>
              <option value="45分">45分</option>
            </select>
          </p>
          <p>
            <label htmlFor="message">
              商談内容（必須）　　
            </label>
            <textarea
              rows="15"
              name="message"
              required
            ></textarea>
          </p>
          {contactForm}

        </form>

      </div>
    )
  }
}